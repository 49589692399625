<template>
  <div class="warp">
    <div class="top-warp">
      <div class="tip-name">网点统计</div>
      <div class="select-time">
        <van-cell :value="date" @click="show = true" />
        <van-calendar v-model:show="show" type="range" @confirm="onConfirm" :allow-same-day="true" :min-date="minDate" :max-date="maxDate"/>
      </div>
    </div>
    <div class="data-info">
      <div class="first-row">
        <div class="val">{{ dataCountInfos.totalOrders }}</div>
        <div>订单数</div>
      </div>
      <div class="first-row">
        <div class="val">{{ dataCountInfos.wechatPay }}</div>
        <div>微信支付</div>
      </div>
      <div class="first-row">
        <div class="val">{{ dataCountInfos.balancePay }}</div>
        <div>余额支付</div>
      </div>
    </div>
    <div v-for="(item, index) in dataCountInfos.statisticsVos" :key="index" class="item" @click="toDetail(item)">
      <div class="title">{{ item.netName }}</div>
      <div class="infos">
        <span class="span">货架：{{ item.vmcCount }}个</span>
        <span class="span">订单数：<span class="_F4730C">{{ item.totalOrders }}</span>个</span>
        <span class="span">实收额：<span class="_F4730C">{{ item.sales }}</span></span>
      </div>
      <van-icon name="arrow" class="arrow" />
    </div>
  </div>
</template>

<script>
import { netNameStatisticsData } from '../../assets/api/index'
const dateNow = new Date();
const year = dateNow.getFullYear();
const month = dateNow.getMonth() > 8 ? dateNow.getMonth()+1 : `0${dateNow.getMonth()+1}`;
const day = dateNow.getDate() > 9 ? dateNow.getDate() : `0${dateNow.getDate()}`;
import { shopOrderStatus } from '../../util/orderStatus.js';
export default {
  data() {
    return {
      maxDate: new Date(year, month, day),
      minDate: new Date(2021,11,1),
      date: `${year}-${month}-${day} - ${year}-${month}-${day}`,
      startDate: `${year}-${month}-${day}`,
      endDate: `${year}-${month}-${day}`,
      show: false,
      dataCountInfos: [],
      shopOrderStatus: shopOrderStatus,
      countInfos: {
        vmcSalesOrder: 0,
        vmcSales: 0,
        vmcReceipts: 0
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth()>8?`${date.getMonth() + 1}`: `0${date.getMonth() + 1}`}-${date.getDate()>9?date.getDate():`0${date.getDate()}`}`;
    },
    onConfirm(val) {
      const [start, end] = val;
      this.show = false;
      this.startDate = this.formatDate(start)
      this.endDate = this.formatDate(end)
      this.date = `${this.startDate} - ${this.endDate}`;
      this.initData()
    },
    initData() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate
      }
      netNameStatisticsData(params).then( res => {
        if (res.data.code == 200) {
          this.dataCountInfos = res.data.data
        }
      })
    },
    toDetail(item) {
      this.$router.push({
        name: 'siteInfos',
        query: {
          netId: item.netId,
          netName: item.netName,
          vmcCount: item.vmcCount,
          startDate: this.startDate,
          endDate: this.endDate,
          totalOrders: item.totalOrders,
          sales: item.sales,
          wechatPay: item.wechatPay,
          balancePay: item.balancePay
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;
  .top-warp {
    width: 100%;
    display: flex;
    height: 44px;
    line-height: 44px;

    .tip-name {
      width: 100px;
      font-weight: 600;
      font-size: 20px;
      margin-right: 12px;
    }

    .select-time {
      width: calc(100% - 112px);
    }
  }
  .data-info {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .first-row {
      height: 80px;
      width: calc(33% - 5px);
      text-align: center;
      border-radius: 8px;
      border: 1px solid #999;

      .val {
        font-size: 18px;
        font-weight: 600;
        line-height: 44px;
      }
    }
  }
  .item {
    margin: 10px 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dcdee0;
    position: relative;

    .title {
      height: 24px;
      line-height: 24px;
      font-size: 16px;
    }
    .infos {
      height: 24px;
      line-height: 24px;
      .span {
        margin-right: 16px;
        color: #666;
      }
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 40%;
      font-size: 16px;
      color: #999
    }
  }
}
</style>
