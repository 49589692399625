const shopOrderStatus = [
  '', '待付款', '待发货', '待收货', '待评价', '已完成', '已关闭','已退款','退款中','退款失败','待接单'
]
const siteOrderStatus = [
  '','待付款','','支付成功,货品不足','','已完成','已关闭','已退款','已退款','退款失败',''
]
// 第二个已退款是退款中

export {
  shopOrderStatus,
  siteOrderStatus
}
